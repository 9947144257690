import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Metrics = (props) => {
  const { data, date } = props;

  var point = '··'
  const msg = `${point} O controle do PROCESSO é obtido através do monitoramento dos grãos na umidade de entrada e na umidade no início na câmara de resfriamento, onde o cálculo de eficiência de secagem é aplicado na regulagem automática no fluxo de saída. ${point}`;

  return (
    <div className="text-center">
      <h5 style={{
        "background": "#ffffffe5",
        "position": "fixed",
        "bottom": "0",
        "width": "99%",
        "border": "solid 1px #286632",
        "borderRadius": "8px",
        "padding": "8px",
        "marginLeft": "8px",
        "marginRight": "8px",
      }}>
        <div className="horizontal-scrolling-items">
          <div className="horizontal-scrolling-items__item" style={{ "color": "#ffffff" }}> {' --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- '} </div>
          <div className="horizontal-scrolling-items__item"> {msg} </div>
          <div className="horizontal-scrolling-items__item" style={{ "color": "#ffffff" }}> {' --------------------------------------------------------------------------------------------------------------------------------------- '} </div>
        </div>
      </h5>

      <Container style={{ "position": "fixed", "color": "#286632", "padding": "8px", }} fluid>
        <Row>
          <Col xl={1}>
            <h5 className="update" style={{ "position": "fixed", "color": "#286632", "fontSize": "14px", "marginTop": "45vh" }}>
              {'Ultima atualização'.toUpperCase()} <br /> {date ? date : "---"}
            </h5>
          </Col>
          <Col xl={6}>
            <h4>{'Processo de secagem originação.'.toUpperCase()}</h4>
          </Col>
          <Col xl={5}>
            <h4>{'Processo de resecagem industrial.'.toUpperCase()}</h4>
          </Col>
        </Row>
      </Container>
      {data && data.map((item) => {
        return (
          <div key={item.id}>
            <div className={`equip-${item.id}`}>
              <img className="image-shadow" src={"./img/silo.png"} width={90} alt="silo" />
            </div>
            <div className={`seta-${item.id}`}></div>
            <div className={`metrics-${item.id}`}>
              <p className="humidity">UMIDADE: {item.humidity}% </p>
              <p className="temperature"> DENSIDADE: {item.density} </p>
              <p className="temperature"> TEMPERATURA: {item.temperature}°C </p>
            </div>
          </div>
        )
      })}
      <div className="div-img-silo">
        <video width="100%" height="100%" className='videoTag' autoPlay loop muted>
          <source src={'./img/video_silo.mp4'} width="100%" type='video/mp4' />
        </video>
      </div>
    </div>
  )
}
export default Metrics;
