import React from "react"
import { Col, Row } from 'react-bootstrap'

const SensorTemp = (props) => {
  const { sensorEntrada, sensorSaida } = props

  return (
    <Row>
      <div style={{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "marginTop": "0px",
        "marginBottom": "0px"
      }}>
        {sensorEntrada.data && sensorSaida.data ?
          <h6 style={{ "fontSize": "12px", "marginBottom": "3px", "marginTop": "3px" }}>Ultima atualização de <b>entrada</b> <br /> {sensorEntrada.data[0].date}</h6>
          : <h6 style={{ "fontSize": "12px", "marginBottom": "3px", "marginTop": "3px" }}>Sem dados!<br /></h6>
        }
      </div>
      {sensorEntrada.data &&
        <>
          <Col>
            <div className='side-card' style={{ "textAlign": "center" }}>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>Temperatura</p>
              {isNaN(parseFloat(sensorEntrada.data[0].temp))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}>{sensorEntrada.data[0].temp.toFixed(1) + '°C'}</p>
              }
            </div>
          </Col>
          <Col>
            <div className='side-card' style={{ "textAlign": "center" }}>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>Umidade</p>
              {isNaN(parseFloat(sensorEntrada.data[0].umid))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}>{sensorEntrada.data[0].umid.toFixed(1) + '%'}</p>
              }
            </div>
          </Col>
        </>
      }
      <div style={{
        "display": "flex",
        "justifyContent": "space-between",
        "alignItems": "center",
        "marginTop": "0px",
        "marginBottom": "0px"
      }}>
        {sensorSaida.data && sensorSaida.data ?
          <h6 style={{ "fontSize": "12px", "marginBottom": "3px", "marginTop": "3px" }}>Ultima atualização de <b>saída</b> <br /> {sensorSaida.data[0].date}</h6>

          : <h6 style={{ "fontSize": "12px", "marginBottom": "3px", "marginTop": "3px" }}>Sem dados!<br /></h6>
        }
      </div>
      {sensorSaida.data &&
        <>
          <Col>
            <div className='side-card' style={{ "textAlign": "center" }}>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>Temperatura</p>
              {isNaN(parseFloat(sensorSaida.data[0].temp))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}>{sensorSaida.data[0].temp.toFixed(1) + '°C'}</p>
              }
            </div>
          </Col>
          <Col>
            <div className='side-card' style={{ "textAlign": "center" }}>
              <p style={{ "fontSize": "11px", "marginBottom": "0px" }}>Umidade</p>
              {isNaN(parseFloat(sensorSaida.data[0].umid))
                ? <p style={{ "fontSize": "11px", "fontWeight": "500" }}>Sem dados!</p>
                : <p style={{ "fontSize": "17px", "fontWeight": "500" }}>{sensorSaida.data[0].umid.toFixed(1) + '%'}</p>
              }
            </div>
          </Col>
        </>
      }
    </Row>
  )
}
export default SensorTemp
