import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Fragment } from "react";
import useAuth from './hooks/useAuth'
import Dashboard from './pages/Dashboard/Dashboard'
import Sensores from './pages/sensores/Sensores';
import Signin from './pages/Signin/Signin'
import Silo from './pages/Silo/Silo';
import Medidas from './pages/Medidas/Medidas';

function App() {
  const Private = ({ Item }) => {
    const signed = useAuth();
    return signed ? <Item /> : <Signin />;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Fragment>
          <Routes>

            <Route exact path="/dashboard" element={<Private Item={Dashboard} />} />
            <Route exact path="/medidas" element={<Private Item={Medidas} />} />
            <Route exact path="/sensores" element={<Private Item={Sensores} />} />
            <Route exact path="/apresentacao" element={<Private Item={Silo} />} />

            <Route path="*" element={<Signin />} />

          </Routes>
        </Fragment>
      </BrowserRouter>
    </div>
  );
}
export default App;