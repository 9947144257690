import React, { useEffect, useState } from "react";
import Card from './components/card';
import axios from 'axios';

const SideBar = (props) => {
  const { sideBar } = props;

  const [entrada, setEntrada] = useState([])
  const [saida, setSaida] = useState([])

  const [statusEntrada, setStatusEntrada] = useState('')
  const [statusSaida, setStatusSaida] = useState('')

  const urlEntrada = sideBar.equipamentos.entrada.ns;
  const urlSaida = sideBar.equipamentos.saida.ns;

  const fetchData = async (serial, setData, setStatus) => {
    try {
      let { data } = await axios.get(`${process.env.REACT_APP_API_MONITORA}/api/v1/lastone/${serial}`);
      setData(data);
    } catch (error) {
      console.error(error);
      setData([]);
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_MONITORA}/connection/status/serial/${serial}`);
      setStatus(response.data.status);
    } catch (error) {
      console.error(error);
      setStatus("offline");
    }
  };

  const atualizaDados = async () => {
    if (urlEntrada && urlEntrada.length === 10) {
      await fetchData(urlEntrada, setEntrada, setStatusEntrada);
    }
    if (urlSaida && urlSaida.length === 10) {
      await fetchData(urlSaida, setSaida, setStatusSaida);
    }
  };

  useEffect(() => {
    atualizaDados()
    const intervalId = setInterval(() => {
      atualizaDados()
    }, 30000)
    return () => clearInterval(intervalId);
  }, [sideBar])

  return (
    <div style={{ "padding": "1px" }} className="side">
      {urlEntrada && urlEntrada.length === 10 && statusEntrada !== "Equipamento não encontrado" &&
        <Card data={entrada} sideBar={sideBar.equipamentos.entrada} equip={urlEntrada} status={statusEntrada} position='entrada' />
      }
      {urlSaida && urlSaida.length === 10 && statusSaida !== "Equipamento não encontrado" &&
        <Card data={saida} sideBar={sideBar.equipamentos.saida} equip={urlSaida} status={statusSaida} position='saída' />
      }
    </div >
  )
}
export default SideBar;
