import React, { useEffect, useState } from "react";
import dateFormat from "dateformat";
import { Line } from 'react-chartjs-2';
import { Card } from 'react-bootstrap';
import { Row, Col } from "react-bootstrap";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Graphics = (props) => {
  const { data, cards, name, curva, position } = props;

  const [userData, setUserData] = useState({
    labels: [],
    datasets: []
  })
  const [userDataOptions, setUserDataOptions] = useState(null)

  const [dataTemperaturaEntrada, setDataTemperaturaEntrada] = useState({
    labels: [],
    datasets: []
  })
  const [dataTemperaturaEntradaOptions, setDataTemperaturaEntradaOptions] = useState(null)

  const [dataPhEntrada, setDataPhEntrada] = useState({
    labels: [],
    datasets: []
  })
  const [dataPhEntradaOptions, setDataPhEntradaOptions] = useState(null)

  var mediahumidity = 0
  var mediaph = 0

  for (let i = 0; i < data.length; i++) {
    mediahumidity += parseFloat(data[i].humidity)
  } mediahumidity = mediahumidity / data.length;

  for (let i = 0; i < data.length; i++) {
    mediaph += parseFloat(data[i].ph)
  } mediaph = mediaph / data.length;

  const updateGraphics = async () => {
    let colorA = '#FA6157'
    let colorB = 'rgba(27, 93, 67, 0.8)'
    let backgroundGraphics = 'rgba(41, 140, 101, 0.6)'

    let labels = await data.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM"));
    const dataFormated = {
      labels,
      datasets: [{
        labels,
        label: 'Umidade',
        data: data.map((data) => (parseFloat(data.humidity).toFixed(1))),
        borderColor: data.map((data) => (parseFloat(data.humidity) > cards.configs.maxHumidity ? colorA : parseFloat(data.humidity) < cards.configs.minHumidity ? colorA : colorB)),
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          labels: {
            title: {
              color: "grey" //'#286632',
            }
          },
          display: 'auto',
          align: 'end',
          font: {
            weight: 600,
            size: 12,
          }
        },
      },
      {
        label: 'Máx',
        data: data.map((data) => cards.configs.maxHumidity),
        borderColor: '#fdbf5c',
        borderWidth: 2,
        pointRadius: 1,
        pointHoverRadius: 2,
        fill: false,
        datalabels: {
          display: false,
        }
      },
      {
        label: 'Min',
        data: data.map((data) => cards.configs.minHumidity),
        borderColor: '#fdbf5c',
        borderWidth: 2,
        pointRadius: 1,
        pointHoverRadius: 2,
        fill: false,
        datalabels: {
          display: false,
        }
      }
      ]
    }
    setUserData(dataFormated)

    let number = data.map((data) => (parseFloat(data.humidity).toFixed(1)))
    let maxValue = Math.max(...number)
    let minValue = Math.min(...number)

    const optionsUserData = {
      scales: {
        y: {
          min: cards.configs.minHumidity > minValue ? minValue - 2 : cards.configs.minHumidity - 2,
          max: cards.configs.maxHumidity < maxValue ? maxValue + 2 : cards.configs.maxHumidity + 2,
          stepSize: 5,
        },
        x: {},
      }
    };
    setUserDataOptions(optionsUserData)

    //------------------------------------------------------------------------------

    labels = data.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM"));
    const dataTemperaturaEntrada = {
      labels,
      datasets: [{
        label: 'Temperatura',
        data: data.map((data) => data.temperature),
        borderColor: data.map((saida) => (parseFloat(data.temperature) > cards.configs.maxTemperature ? colorA : parseFloat(data.temperature) < cards.configs.minTemperature ? colorA : colorB)),
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          labels: {
            title: {
              color: "grey" //'#286632',
            }
          },
          display: 'auto',
          align: 'end',
          font: {
            weight: 600,
            size: 12,
          }
        },
      },
      {
        label: 'Máx',
        data: data.map((data) => cards.configs.maxTemperature),
        borderColor: '#fdbf5c',
        borderWidth: 2,
        pointRadius: 1,
        pointHoverRadius: 2,
        fill: false,
        datalabels: {
          display: false,
        }
      },
      {
        label: 'Min',
        data: data.map((data) => cards.configs.minTemperature),
        borderColor: '#fdbf5c',
        borderWidth: 2,
        pointRadius: 1,
        pointHoverRadius: 2,
        fill: false,
        datalabels: {
          display: false,
        }
      },
      ]
    }
    setDataTemperaturaEntrada(dataTemperaturaEntrada)

    number = data.map((data) => data.temperature)
    maxValue = Math.max(...number)
    minValue = Math.min(...number)

    const optionsTemperaturaEntrada = {
      scales: {
        y: {
          min: cards.configs.minTemperature > minValue ? minValue - 2 : cards.configs.minTemperature - 2,
          max: cards.configs.maxTemperature < maxValue ? maxValue + 2 : cards.configs.maxTemperature + 2,
          stepSize: 5,
        },
        x: {},
      },
    };
    setDataTemperaturaEntradaOptions(optionsTemperaturaEntrada)

    //------------------------------------------------------------------------------

    labels = data.map(data => dateFormat(data.date, "UTC:dd/mm' 'HH:MM"));
    const dataPhEntrada = {
      labels,
      datasets: [{
        label: 'PH',
        data: data.map((data) => (parseFloat(data.ph).toFixed(0).replace(/\D/g, ""))),
        borderColor: data.map((data) => (parseFloat(data.ph) > cards.configs.maxPH ? colorA : parseFloat(data.ph) < cards.configs.minPH ? colorA : colorB)),
        borderWidth: 2,
        fill: true,
        pointRadius: 4,
        datalabels: {
          labels: {
            title: {
              color: "grey" //'#286632',
            }
          },
          display: 'auto',
          align: 'end',
          font: {
            weight: 600,
            size: 12,
          }
        },
      },
      {
        label: 'Máx',
        data: data.map((data) => cards.configs.maxPH),
        borderColor: '#fdbf5c',
        borderWidth: 2,
        pointRadius: 1,
        pointHoverRadius: 2,
        fill: false,
        datalabels: {
          display: false,
        }
      },
      {
        label: 'Min',
        data: data.map((data) => cards.configs.minPH),
        borderColor: '#fdbf5c',
        borderWidth: 2,
        pointRadius: 1,
        pointHoverRadius: 2,
        fill: false,
        datalabels: {
          display: false,
        }
      },
      ]
    }
    setDataPhEntrada(dataPhEntrada)

    number = data.map((data) => (parseFloat(data.ph).toFixed(0).replace(/\D/g, "")))
    maxValue = Math.max(...number)
    minValue = Math.min(...number)

    const optionsPhEntrada = {
      scales: {
        y: {
          min: cards.configs.minPH > minValue ? minValue - 2 : cards.configs.minPH - 2,
          max: cards.configs.maxPH < maxValue ? maxValue + 10 : cards.configs.maxPH + 10,
          stepSize: 5,
        },
        x: {},
      },
    };
    setDataPhEntradaOptions(optionsPhEntrada)
  }

  //------------------------------------------------------------------------------

  useEffect(() => {
    updateGraphics()
  }, [cards, data])

  const plugins = {
    afterDraw: function (chart) {
      if (chart.data.datasets.length > 0) {
        if (chart.data.datasets[0].data.length === 0) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 160).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "top";
          var text = "Nenhuma Informação Disponível",
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }
    },
  }

  return (
    <Card className="mb-2 p-1" border="secondary">
      <h4 className='card-h6-barchart' style={{ "marginBottom": "0px" }}>{position.toUpperCase()} - {name.toUpperCase()} - {curva ? curva : 'Curva não selecionada'.toUpperCase()}</h4>
      <Row>
        <Col className="text-center" xs={6}>
          <p className="mb-0 mt-1">Média Umidade: {mediahumidity && mediahumidity !== "NaN" ? mediahumidity.toFixed(1) : 0}</p>
          <Line options={userDataOptions} data={userData} plugins={[ChartDataLabels, plugins]} height={150} />
        </Col>
        <Col className="text-center" xs={6}>
          <p className="mb-0 mt-1">Média PH: {mediaph && mediaph !== "NaN" ? mediaph.toFixed(1) : 0}</p>
          <Line options={dataPhEntradaOptions} data={dataPhEntrada} plugins={[ChartDataLabels, plugins]} height={150} />
        </Col>
      </Row>
    </Card>
  )
}
export default Graphics