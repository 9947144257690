import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { BsSearch } from 'react-icons/bs';

const Search = (props) => {
  const { onSearch, onSearchData, searchData } = props;

  return (
    <InputGroup className="m-0">
      <Form.Control
        size='sm'
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        placeholder="Filtro"
        onChange={onSearchData}
        value={searchData}
        onKeyUp={onSearch} />
      <InputGroup.Text>
        <BsSearch />
      </InputGroup.Text>
    </InputGroup>
  );
};
export default Search;
