import React from "react";
import { Card } from 'react-bootstrap';

const Sensor = (props) => {
  const { data } = props;

  return (
    <Card style={{
      "border": "solid 1px grey",
      "boxShadow": "0px 0px 5px 3px grey",
      "marginBottom": "10px",
      "background": "rgb(255 255 255 / 80%)"

    }}>
      <img style={{ "marginLeft": "auto", "marginRight": "auto" }} src={'./img/gif_silo.gif'} width="100%" alt="" />
      {data ?
        <>
          {data[0] ?
            <div className="sens-0">{data[0] ? data[0].Sens : 'null'}</div>
            : null
          }
          {data[1] ?
            <div className="sens-1">{data[1] ? data[1].Sens : 'null'}</div>
            : null
          }
          {data[2] ?
            <div className="sens-2">{data[2] ? data[2].Sens : 'null'}</div>
            : null
          }
          {data[3] ?
            <div className="sens-3"> {data[3] ? data[3].Sens : 'null'}</div>
            : null
          }
          {data[4] ?
            <div className="sens-4"> {data[4] ? data[4].Sens : 'null'}</div>
            : null
          }
          {data[5] ?
            <div className="sens-5">{data[5] ? data[5].Sens : 'null'}</div>
            : null
          }
        </>
        : null
      }
    </Card >
  )
}
export default Sensor
