import Chart from 'chart.js/auto';
import React, { useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import SideBar from './components/SideBar';
import Graphics from '../../components/Grafico/Graphics';
import SensorTemp from './components/components/sensorTemp';
import Loader from '../../components/Loader/Loader';
import axios from 'axios';
import { Col, Row } from 'react-bootstrap';

var ids = []
var currentId = 1
var roots = []

const Graficos = (props) => {
  const { setProgress } = props;

  const param = 30;
  const [instrumentos] = useState(JSON.parse(localStorage.getItem('instruments')))
  const [id] = useState(JSON.parse(localStorage.getItem('empresa'))._id)

  const [cards, setCards] = useState()
  const [entrada, setEntrada] = useState([])
  const [saida, setSaida] = useState([])
  const [datasets, setDatasets] = useState()
  const [numberPosition, setNumberPosition] = useState()
  const [loaders, setLoaders] = useState(false)

  const [curvaEntrada, setCurvaEntrada] = useState("")
  const [curvaSaida, setCurvaSaida] = useState("")

  const entradaSensor = 'SHTM-00006'
  const saidaSensor = 'SHTM-00004'

  const [sensorEntrada, setSensorEntrada] = useState([])
  const [sensorSaida, setSensorSaida] = useState([])

  const addId = () => {
    let index = 1
    roots = instrumentos.map(i => {
      i["id"] = index
      index++
      return i
    })
  }

  const objectStyle = {
    border: "solid 1px black",
    backgroundColor: "rgb(220, 231, 233, 0.8)",
    padding: "5px",
    color: "black",
    boxShadow: "0px 0px 10px 5px grey"
  };

  const objectStyleValid = {
    marginBottom: "5px",
    border: "solid 1px grey",
    padding: "5px",
    color: "#262626",
    backgroundColor: "rgba(255, 255, 255, 0.8)"
  };

  const handleGetCurvaEntrada = (data) => {
    setCurvaEntrada(data[data.length - 1].grain)
  }

  const handleGetCurvaSaida = (data) => {
    setCurvaSaida(data[data.length - 1].grain)
  }

  const handleGraphics = async (j) => {
    var instrument = instrumentos.filter(i => i.id === currentId)[0]
    setNumberPosition(instrument)
    if (!instrument) {
      return;
    }
    setLoaders(true)
    var data = []
    try {
      if (instrumentos[j].equipamentos.entrada.ns) {
        data = await axios.get(`${process.env.REACT_APP_API_MONITORA}/measurements/byEquip/lastn/${instrumentos[j].equipamentos.entrada.ns}/${param}`)
        if (data.data.length) {
          let listaRevertida = [...data.data].reverse();
          setEntrada(listaRevertida);
          handleGetCurvaEntrada(listaRevertida)
        }
      } else {
        setEntrada([]);
        setCurvaSaida("")
      }
    } catch (error) {
      console.log(error);
      setEntrada([]);
      setCurvaEntrada("")
    }
    try {
      if (instrumentos[j].equipamentos.saida.ns) {
        data = await axios.get(`${process.env.REACT_APP_API_MONITORA}/measurements/byEquip/lastn/${instrumentos[j].equipamentos.saida.ns}/${param}`)
        if (data.data.length) {
          let listaRevertida = [...data.data].reverse();
          setSaida(listaRevertida);
          handleGetCurvaSaida(listaRevertida)
        }
      } else {
        setSaida([]);
        setCurvaSaida("")
      }
    } catch (error) {
      console.log(error);
      setSaida([]);
      setCurvaSaida("")
    }
    setLoaders(false)
  }

  const showValues = () => {
    setCards(instrumentos.filter(i => i.id === currentId)[0])
    let x = [...instrumentos]
    let test = x.findIndex(x => x.id === currentId);
    let x1 = x.slice(0, test)
    let x2 = x.slice(test, x.length)
    setDatasets(x2.concat(x1))
  }

  useEffect(() => {
    addId()
    handleGraphics(currentId - 1)
    showValues()
    if (id === "65ccc9d8d99bff2fa1bec026") {
      GetSensor()
    }
    const intervalId = setInterval(() => {
      let index = ids.indexOf(currentId);
      if (index >= 0 && index < ids.length - 1) {
        currentId = ids[index + 1]
      }
      else {
        currentId = ids[0]
      }
      handleGraphics(currentId - 1)
      showValues();
      setProgress(0)
      if (id === "65ccc9d8d99bff2fa1bec026") {
        GetSensor()
      }
    }, 60000)
    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => {
    if (!instrumentos) return;
    const _ids = instrumentos.map(i => i.id).sort()
    ids = _ids
    if (ids.length > 0) {
      currentId = ids[0]
    }
  }, [])

  const GetSensor = async () => {
    try {
      var data = await axios.get(`${process.env.REACT_APP_API_MONITORA}/measurements/dryer/one/${entradaSensor}`)
      setSensorEntrada(data.data);
    } catch (error) {
      console.log(error);
    }
    try {
      var data = await axios.get(`${process.env.REACT_APP_API_MONITORA}/measurements/dryer/one/${saidaSensor}`)
      setSensorSaida(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Row>
        {loaders && <Loader />}
        <Col xl={3} md={12} >
          {sensorEntrada.serialnumber && sensorSaida.serialnumber ?
            <Card className="card-grafico mb-2" style={objectStyleValid} >
              <h6 className='card-h6-barchart'>{'Sensores de temperatura'.toUpperCase()}</h6>
              <SensorTemp
                sensorEntrada={sensorEntrada}
                sensorSaida={sensorSaida}
              />
            </Card>
            : null}
          {datasets && numberPosition &&
            datasets.map((item) => {
              return (
                <Card key={item.id} className="card-grafico mb-1" style={item.id === numberPosition.id ? objectStyle : objectStyleValid} >
                  <h6 className='card-h6-barchart'>{item.name.toUpperCase()} - {item.curva.toUpperCase()}</h6>
                  <SideBar sideBar={item} />
                </Card>
              )
            })}
        </ Col>

        <Col xl={9} md={12} className="mb-1 class-grafico">
          {cards && entrada.length ?
            <Graphics
              data={entrada}
              cards={cards.equipamentos.entrada}
              name={cards.name}
              curva={curvaEntrada}
              position="Equipamento de entrada"
            /> : null
          }
          {cards && saida.length ?
            <Graphics
              data={saida}
              cards={cards.equipamentos.saida}
              name={cards.name}
              curva={curvaSaida}
              position="Equipamento de saída"
            /> : null
          }
        </Col>
      </Row>
    </>
  )
}
export default Graficos;
