import ClipLoader from "react-spinners/ClipLoader";

const Loader = () => {
  
  return (
    <div className="loader">
      <ClipLoader color={"silver"} size={130} loading={true} />
    </div>
  )
}
export default Loader;