import React from "react";
import { Card, Container, Row, Col } from 'react-bootstrap';

const DadosSensor = (props) => {
  const { data } = props;

  return (
    <Card style={{
      "border": "solid 1px grey",
      "boxShadow": "0px 0px 5px 3px grey",
      "background": "rgb(255 255 255 / 80%)"
    }}>
      {data[0] ?
        <h4 className='card-h6-barchart'>
          {'Data da ultima atualização: '.toUpperCase()}{data[0].date}
        </h4>
        : <h2 className="text-center">Sem dados para os sensores</h2>
      }

      <Container>
        <Row>
          {data ? data.map(i => {
            return (
              <Col key={i.Sens}>
                <div className="card-dados-sensor m-0 mb-1 p-1 rounded">
                  <div className="text-center card-h6-barchart" >
                    SENSOR: {i.Sens.toUpperCase()}
                  </div>

                  <Row className="mt-4 mb-3">
                    <Col className="text-center">
                      <div className="col-sensor">
                        <p style={{ "marginBottom": "0px", "fontSize": "12px" }}>
                          Temperatura
                        </p>
                        <p style={{ "marginBottom": "0px", "fontSize": "18px" }}>
                          <b> {i.temp.toFixed(1)}° </b>
                        </p>
                      </div>
                    </Col>
                    <Col className="text-center">
                      <div className="col-sensor">
                        <p style={{ "marginBottom": "0px", "fontSize": "12px" }}>
                          Umidade
                        </p>
                        <div style={{ "marginBottom": "0px", "fontSize": "18px" }}>
                          <b> {i.umid.toFixed(1)}% </b>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            )
          }) : <div>
            Sem dados.
          </div>}
        </Row>
      </Container>
    </Card>
  )
}
export default DadosSensor