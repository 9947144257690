import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Metrics from "./components/Metrics";

const Silo = (props) => {

  const [data, setData] = useState();

  const handleMetrics = async () => {
    var j = [];

    var humidity_0 = (Math.random() * (20 - 25) + 25).toFixed(2);
    var humidity_1 = (Math.random() * (30 - 30) + 20).toFixed(2);
    var humidity_2 = (Math.random() * (11 - 14.5) + 14.5).toFixed(2);
    var humidity_3 = (Math.random() * (11.5 - 14.3) + 14.3).toFixed(2);
    var humidity_4 = (Math.random() * (10 - 12) + 12).toFixed(2);
    var humidity_5 = (Math.random() * (9 - 9.5) + 9.5).toFixed(2);

    j = [{
      "id": 0,
      "hour": new Date().toLocaleString(),
      "humidity": humidity_0,
      "temperature": (Math.random() * (36 - 29) + 29).toFixed(2),
      "density": (Math.random() * (690 - 670) + 670).toFixed(2),
    },
    {
      "id": 1,
      "hour": new Date().toLocaleString(),
      "humidity": (humidity_0 - ((25 * humidity_0) / 100)).toFixed(2),
      "temperature": (Math.random() * (78 - 65) + 65).toFixed(2),
      "density": (Math.random() * (700 - 680) + 680).toFixed(2),
    },
    {
      "id": 2,
      "hour": new Date().toLocaleString(),
      "humidity": humidity_2,
      "temperature": (Math.random() * (48 - 38) + 38).toFixed(2),
      "density": (Math.random() * (710 - 690) + 690).toFixed(2),
    },
    {
      "id": 3,
      "hour": new Date().toLocaleString(),
      "humidity": humidity_3,
      "temperature": (Math.random() * (36 - 29) + 29).toFixed(2),
      "density": (Math.random() * (720 - 700) + 700).toFixed(2),
    },
    {
      "id": 4,
      "hour": new Date().toLocaleString(),
      "humidity": (humidity_3 - ((15 * humidity_3) / 100)).toFixed(2),
      "temperature": (Math.random() * (78 - 65) + 65).toFixed(2),
      "density": (Math.random() * (730 - 710) + 710).toFixed(2),
    },
    {
      "id": 5,
      "hour": new Date().toLocaleString(),
      "humidity": humidity_5,
      "temperature": (Math.random() * (48 - 38) + 38).toFixed(2),
      "density": (Math.random() * (740 - 720) + 720).toFixed(2),
    }]

    setData(j);
  }

  useEffect(() => {
    handleMetrics()
    setInterval(() => {
      console.log("Reload...");
      handleMetrics();
    }, (Math.random() * (15000 - 10000) + 10000))
  }, [])

  return (
    <>
      <button className="button-exit" style={{ "border": "none" }}
        onClick={() => {
          localStorage.clear();
          window.location.href = "/"
        }}>
      </button>
      <Row className="mt-0">
        <Col className="mt-0">
          <Metrics data={data} date={new Date().toLocaleString("pt-BR", { "hour12": false })} />
        </Col>
      </Row>
    </>
  )
}
export default Silo;
